<template>
  <div class="card">
    <columns-modal id='columns-modal' :column_definitions='column_definitions' :default_selected_columns='default_selected_columns' @change='setColumns'></columns-modal>
    <div class="card-header">
      <slot name="card-title">
        <h2 class='card-title'>{{ $t('components.data_table.title') }}</h2>
      </slot>

      <div class="card-options">
        <template v-for="extra_action in extra_actions_with_search">
          <a :href='extra_action.search_url' class="btn btn-secondary"><i :class="['icon', extra_action.icon]"></i>{{ extra_action.label }}</a>
        </template>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="icon fe fe-list"></i>
            {{ $t('components.data_table.export') }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a :href="csv_url" class="dropdown-item">{{ $t('components.data_table.this_page_csv') }}</a>
            <a :href="xlsx_url" class="dropdown-item">{{ $t('components.data_table.this_page_xlsx') }}</a>
            <div class="dropdown-divider"></div>
            <a :href="full_csv_url" class="dropdown-item">{{ $t('components.data_table.all_pages_csv') }}</a>
            <a :href="full_xlsx_url" class="dropdown-item">{{ $t('components.data_table.all_pages_xlsx') }}</a>
            </div>
        </div>
        <button type="button" v-modal.columns-modal class="btn btn-secondary"><i class="icon fe fe-settings"></i></button>
      </div>
    </div>
      <div v-if="show_loader" class="vbt-table-overlay">
        <spinner></spinner>
      </div>
      <div class="table-responsive">
        <table class="table" :class="card-table">
          <thead>
          <tr>
            <select-all-rows-check-box v-if="checkbox_rows"
                                       :all-rows-selected="allRowsSelected"
                                       :current-page-selection-count="currentPageSelectionCount"
                                       @select-all-row-checkbox="selectAllCheckbox"/>

            <slot name="columns" :columns="vbt_columns">
              <template v-for="(column, key, index) in vbt_columns">
                <th :key="index" v-on="isSortableColumn(column) ? { click: () => updateSortQuery(column) } : {}" class="vbt-column-header" :class="columnClasses(column)">
                  <slot :name="'column_' + getCellSlotName(column)" :column="column">
                    {{column.label}}
                  </slot>

                  <template v-if='isSortableColumn(column)'>
                    <SortIcon :sort="query.sort" :column="column">
                      <template slot="vbt-sort-asc-icon">
                        <slot name="sort-asc-icon">
                          &#x1F825;
                        </slot>
                      </template>
                      <template slot="vbt-sort-desc-icon">
                        <slot name="sort-desc-icon">
                          &#x1F827;
                        </slot>
                      </template>
                      <template slot="vbt-no-sort-icon">
                        <slot name="no-sort-icon">
                          &#x1F825;&#x1F827;
                        </slot>
                      </template>
                    </SortIcon>
                  </template>
                </th>
              </template>
            </slot>
          </tr>
          </thead>
          <tbody>
          <!-- data rows stars here -->
          <row v-for="(row, index) in vbt_rows" :key="index"
               :row="row"
               :columns="vbt_columns"
               :row-index="index"
               :checkbox-rows="checkbox_rows"
               :rows-selectable="rows_selectable"
               :selected-items="selected_items"
               :highlight-row-hover="highlight_row_hover"
               :highlight-row-hover-color="rowHighlightColor"
               :prop-row-classes="classes.row"
               :prop-cell-classes="classes.cell"
               :unique-id="uniqueId"
               @add-row="handleAddRow"
               @remove-row="handleRemoveRow">
            <template v-for="(column) in vbt_columns" :slot="'vbt-'+getCellSlotName(column)">
              <slot :name="getCellSlotName(column)" :row="row" :column="column" :cell_value="getValueFromRow(row,column.name)">
                {{getValueFromRow(row,column.name)}}
              </slot>
            </template>
          </row>
          <!-- empty row starts here -->
          <tr v-show="vbt_rows == 0">
            <td :colspan="headerColSpan">
              <slot name="empty-results">
                No results found
              </slot>
            </td>
          </tr>
          <!-- empty row ends here -->

          <!-- data rows ends here -->

          <!-- Pagination row starts here -->
          <tr v-if="showPaginationRow" class="footer-pagination-row">
            <td :colspan="headerColSpan">
              <div class="row vbt-pagination-row no-gutters">
                <!-- pagination starts here -->
                <div class="col-md-8">
                  <div v-if="pagination">
                    <Pagination :page.sync="page" :per_page.sync="per_page" :per_page_options="per_page_options" :total="rowCount" :num_of_visibile_pagination_buttons="num_of_visibile_pagination_buttons">
                      <template slot="vbt-paginataion-previous-button">
                        <slot name="paginataion-previous-button">
                          &laquo;
                        </slot>
                      </template>
                      <template slot="vbt-paginataion-next-button">
                        <slot name="paginataion-next-button">
                          &raquo;
                        </slot>
                      </template>
                    </Pagination>
                  </div>
                </div>
                <!-- pagination ends here -->

                <!-- pagination info start here -->
                <div class="col-md-4">
                  <div class="text-right justify-content-center">
                    <template v-if="pagination_info">
                      <slot name="pagination-info" :currentPageRowsLength="currentPageRowsLength" :filteredRowsLength="filteredRowsLength" :originalRowsLength="originalRowsLength">
                        <template v-if="currentPageRowsLength != 0">
                          {{ $t('components.data_table.entries', { size: filteredRowsLength }) }}
                        </template>
                        <template v-else>
                          {{ $t('components.data_table.no_entries_found') }}
                        </template>
                        <template>
                          {{ $t('components.data_table.total_records', { size: originalRowsLength }) }}
                        </template>
                      </slot>
                    </template>
                    <template v-if="selected_rows_info && pagination_info && isSelectable">
                      <slot name="pagination-selected-rows-separator">
                        |
                      </slot>
                    </template>
                    <template v-if="selected_rows_info && isSelectable">
                      <slot name="selected-rows-info" :selectedItemsCount="selectedItemsCount">
                        {{selectedItemsCount}} rows selected
                      </slot>
                    </template>
                  </div>
                </div>
                <!-- pagination info ends here -->
              </div>
            </td>
          </tr>
          <!-- Pagination ends starts here -->

          </tbody>
        </table>
      </div>
    <div class="card-footer" v-if="card_mode">
      <slot name="card-footer">
      <div class="row">
        <!-- pagination starts here -->
        <div class="col-md-6">
          <div v-if="pagination">
            <Pagination :page.sync="page" :per_page.sync="per_page" :per_page_options="per_page_options" :total="rowCount" :num_of_visibile_pagination_buttons="num_of_visibile_pagination_buttons">
            <template slot="vbt-paginataion-previous-button">
              <slot name="paginataion-previous-button">
              &laquo;
              </slot>
            </template>
            <template slot="vbt-paginataion-next-button">
              <slot name="paginataion-next-button">
              &raquo;
              </slot>
            </template>
            </Pagination>
          </div>
        </div>
        <!-- pagination ends here -->

        <!-- pagination info start here -->
        <div class="col-md-6">
          <div class="text-right justify-content-center">
            <template v-if="pagination_info">
              <slot name="pagination-info" :currentPageRowsLength="currentPageRowsLength" :filteredRowsLength="filteredRowsLength" :originalRowsLength="originalRowsLength">
              <template v-if="currentPageRowsLength != 0">
                {{ $t('components.data_table.entries', { size: filteredRowsLength }) }}
              </template>
              <template v-else>
                {{ $t('components.data_table.no_entries_found') }}
              </template>
              <template>
                {{ $t('components.data_table.total_records', { size: originalRowsLength }) }}
              </template>
              </slot>
            </template>
            <template v-if="pagination_info && selected_rows_info">
              <slot name="pagination-selected-rows-separator">
              |
              </slot>
            </template>
            <template v-if="selected_rows_info">
              <slot name="selected-rows-info" :selectedItemsCount="selectedItemsCount">
              {{selectedItemsCount}} rows selected
              </slot>
            </template>
          </div>
        </div>
        <!-- pagination info ends here -->
      </div>
      </slot>
    </div>
  </div>
</template>

<script>
import findIndex from "lodash/findIndex";
import range from "lodash/range";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import join from "lodash/join";
import has from "lodash/has";
import extend from "lodash/extend";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import differenceWith from "lodash/differenceWith";
import differenceBy from "lodash/differenceBy";
import intersectionWith from "lodash/intersectionWith";
import intersectionBy from "lodash/intersectionBy";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import omit from "lodash/omit";
import clone from "lodash/clone";

import Row from "./Row.vue";
import CheckBox from "./CheckBox.vue";
import SelectAllRowsCheckBox from "./SelectAllRowsCheckBox.vue";
import SortIcon from "./SortIcon.vue";
import Pagination from "./Pagination.vue";
import Simple from "./Filters/Simple.vue";
import MultiSelect from "./Filters/MultiSelect.vue";

import {
  EventBus
} from './event-bus.js';

import { BModal, VBModal } from 'bootstrap-vue'
import { BSpinner } from 'bootstrap-vue'

import ColumnsModal from 'components/ColumnsModal'

export default {
  name: "VueBootstrap4Table",
  props: {
    rows: {
      type: Array,
      required: true
    },
    csv_url: {
      type: String,
      required: true
    },
    full_csv_url: {
      type: String,
      required: true
    },
    xlsx_url: {
      type: String,
      required: true
    },
    full_xlsx_url: {
      type: String,
      required: true
    },
    extra_actions_with_search: {
      type: Array,
      default: []
    },
    default_selected_columns: {
      type: Array,
      required: true
    },
    column_definitions: {
      type: Object,
      required: true
    },
    totalRows: {
      type: Number,
      default: 0
    },
    show_loader: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    customFilters: {
      type: Array,
      default: function () {
        return [];
      }
    },
    eager_load: {
      type: Boolean,
      required: true,
    }
  },
  data: function () {
    return {
      vbt_rows: [],
      vbt_columns: [],
      query: {
        sort: [],
        filters: {},
      },
      page: 1,
      per_page: 25,
      original_rows: [],
      num_of_visibile_pagination_buttons: 5,
      temp_filtered_results: [],
      pagination: true,
      pagination_info: true,
      checkbox_rows: false,
      selected_items: [],
      highlight_row_hover: true,
      highlight_row_hover_color: "#d6d6d6",
      rows_selectable: false,
      allRowsSelected: false,
      multi_column_sort:false,
      card_title: "",
      per_page_options : [25,50,100],
      show_refresh_button: true,
      show_reset_button: true,
      total_rows: 0,
      card_mode: true,
      selected_rows_info: false,
      lastSelectedItemIndex: null,
      isFirstTime: true,
      isResponsive: true,
      preservePageOnDataChange: false,
    };
  },
  mounted() {
    this.vbt_rows = cloneDeep(this.rows);

    this.$root.$on('search-form-change', _.debounce(this.updateFilters, 750))

    let self = this;
    // check if user mentioned unique id for a column, if not set unique id for all items
    this.original_rows = map(this.vbt_rows, function (element, index) {
      let extra = {};
      if (!self.hasUniqueId) {
        extra.vbt_id = index + 1;
      }
      return extend({}, element, extra);
    });

    this.initialSort();

    this.$nextTick(() => {
      if (this.eager_load) {
        this.emitQueryParams();
      }
    });

    this.handleShiftKey();
  },
  components: {
    'modal': BModal,
    'spinner': BSpinner,
    ColumnsModal,
    Row,
    CheckBox,
    SelectAllRowsCheckBox,
    Simple,
    MultiSelect,
    SortIcon,
    Pagination,
  },
  directives: {
    'modal': VBModal
  },
  methods: {
    setColumns(columns) {
      this.vbt_columns = []
      if (columns) {
        columns.forEach((column_key) => {
          if (this.column_definitions[column_key]) {
            let column_definition = cloneDeep(this.column_definitions[column_key])
            column_definition.vbt_col_id = column_key

            this.vbt_columns.push(column_definition)
          } else {
            throw `did not find column ${column_key} in ${JSON.stringify(this.column_definitions)}`
          }
        })
      }
    },
        initialSort() {
          // TODO optimze this with removing this filter
          let initial_sort_columns =  filter(this.vbt_columns, column => (has(column,'initial_sort') && column.initial_sort == true));

          initial_sort_columns.some(initial_sort_column => {

            let result = findIndex(this.query.sort, { 'vbt_col_id': initial_sort_column.vbt_col_id });

            if(result == -1) {
              // initial sort order validation starts here
              let initial_sort_order = "asc";
              if (has(initial_sort_column,"initial_sort_order")) {
                if (includes(["asc","desc"], initial_sort_column.initial_sort_order)) {
                  initial_sort_order = initial_sort_column.initial_sort_order;
                } else {
                  console.log("invalid initial_sort_order, so setting it to default");
                }
              }
              // initial sort order validation ends here
              this.query.sort.push({
                vbt_col_id: initial_sort_column.vbt_col_id,
                name: initial_sort_column.name,
                order: initial_sort_order,
                caseSensitive: this.isSortCaseSensitive(initial_sort_column)
              });

            }
            // else {
            //     this.query.sort[result].order = initial_sort_column.initial_sort_order;
            // }

            // if multicolum sort sort is false, then consider only first initial sort column
            if (!this.multi_column_sort) {
              return true;
            }
          });
        },

        initFilterQueries() {
          this.query.filters = {}
        },

        isSortCaseSensitive(column) {
          return (column.sortCaseSensitive != undefined) ? column.sortCaseSensitive : true;
        },

        updateSortQuery(column) {

          let result = findIndex(this.query.sort, { 'vbt_col_id': column.vbt_col_id });

          if(result == -1) {

            if (!this.multi_column_sort) {
              this.query.sort = [];
            }
            this.query.sort.push({
              vbt_col_id: column.vbt_col_id,
              name: column.sort_field,
              order: "asc",
              caseSensitive: this.isSortCaseSensitive(column)
            });

          } else {
            this.query.sort[result].order = this.query.sort[result].order == "asc" ? "desc" : "asc";
          }
        },
        isShiftSelection(shiftKey,rowIndex){
          return (shiftKey == true) && (this.lastSelectedItemIndex != null) && (this.lastSelectedItemIndex != rowIndex);
        },
        handleAddRow(payload) {
          let row = this.vbt_rows[payload.rowIndex];
          if (this.isShiftSelection(payload.shiftKey,payload.rowIndex)) {
            let rows = this.getShiftSelectionRows(payload.rowIndex);
            rows.forEach((_row) => {this.addSelectedItem(_row)});
          } else {
            this.addSelectedItem(row);
          }

          this.$emit('on-select-row', {"selected_items":cloneDeep(this.selected_items) ,"selected_item":row});

          let difference = [];

          if (!this.hasUniqueId) {
            difference = differenceWith(this.vbt_rows, this.selected_items, isEqual);
          } else {
            difference = differenceBy(this.vbt_rows, this.selected_items, this.uniqueId);
          }

          if (difference.length == 0) {
            this.allRowsSelected = true;
            // EventBus.$emit('select-select-all-items-checkbox', "from main");
          } else {
            this.allRowsSelected = false;
            // EventBus.$emit('unselect-select-all-items-checkbox', "from main");
          }

          this.lastSelectedItemIndex = payload.rowIndex;
        },

        getActionButtonClass(action){
          return has(action,'class') ? action.class : " btn-secondary";
        },

        handleRemoveRow(payload) {
          let row = this.vbt_rows[payload.rowIndex];
          if (this.isShiftSelection(payload.shiftKey,payload.rowIndex)) {
            let rows = this.getShiftSelectionRows(payload.rowIndex);
            rows.forEach((_row) => {this.removeSelectedItem(_row)});
          } else {
            this.removeSelectedItem(row);
          }
          this.$emit('on-unselect-row', {"selected_items":cloneDeep(this.selected_items),"unselected_item":row});
          // EventBus.$emit('unselect-select-all-items-checkbox');
          this.allRowsSelected = false;
          this.lastSelectedItemIndex = payload.rowIndex;
        },
        addSelectedItem(item) {

          let index = -1;
          if (!this.hasUniqueId) {
            index = findIndex(this.selected_items, (selected_item) => {return isEqual(selected_item, item)});
          } else {
            index = findIndex(this.selected_items, (selected_item) => {return selected_item[this.uniqueId] == item[this.uniqueId]});
          }

          if (index == -1) {
            this.selected_items.push(item);
          }
        },
        selectAllItems() {

          let difference = [];

          if (!this.hasUniqueId) {
            difference = differenceWith(this.vbt_rows, this.selected_items, isEqual);
          } else {
            difference = differenceBy(this.vbt_rows, this.selected_items, this.uniqueId);
          }

          this.selected_items.push(...difference);

          this.$emit('on-all-select-rows', {"selected_items":cloneDeep(this.selected_items) });

        },
        unSelectAllItems() {

          let difference = [];

          if (!this.hasUniqueId) {
            let result = intersectionWith(this.vbt_rows, this.selected_items, isEqual);
            difference = differenceWith(this.selected_items, result, isEqual);
          } else {
            let result = intersectionBy(this.vbt_rows, this.selected_items, this.uniqueId);
            difference = differenceBy(this.selected_items, result, this.uniqueId);
          }

          this.selected_items = difference;

          this.$emit('on-all-unselect-rows', {"selected_items":cloneDeep(this.selected_items)});

        },
        removeSelectedItem(item) {
          // TODO try with findbyId function
          this.selected_items.some((selected_item,index) => {
            if (isEqual(item, selected_item)) {
              this.selected_items.splice(index, 1);
              return true;
            }
          });
        },
        getShiftSelectionRows(rowIndex) {
          let start = 0;
          let end = 0;
          if (this.lastSelectedItemIndex < rowIndex) {
            start = this.lastSelectedItemIndex;
            end = rowIndex + 1;
          } else if (this.lastSelectedItemIndex > rowIndex) {
            start = rowIndex;
            end = this.lastSelectedItemIndex + 1;
          }
          return this.vbt_rows.slice(start,end);
        },
        updateFilters(payload) {
          this.query.filters = payload
        },
        sort() {

          if(this.query.sort.length != 0) {
            let orders = this.query.sort.map(sortConfig => sortConfig.order);

            this.temp_filtered_results = orderBy(this.temp_filtered_results,
              this.query.sort.map(sortConfig => {
              return row => {
                let value = get(row,sortConfig.name);
                if (sortConfig.caseSensitive) return value != null ? value : '';
                return value != null ? value.toString().toLowerCase() : '';
              }
            }),
            orders
            );
          };

          this.paginateFilter();
        },
        paginateFilter() {
          if (this.pagination) {
            let start = (this.page - 1) * this.per_page;
            let end = start + this.per_page;
            this.vbt_rows = this.temp_filtered_results.slice(start, end);
          } else {
            this.vbt_rows = cloneDeep(this.temp_filtered_results);
          }
        },

        selectAllCheckbox() {
          if (this.allRowsSelected || this.currentPageSelectionCount > 0) {
            this.unSelectAllItems();
            this.allRowsSelected = false;
          } else {
            this.selectAllItems();
            this.allRowsSelected = true;
          }
        },

        isSortableColumn(column) {
          if (!has(column,'sort_field')) {
            return false;
          } else {
            return true;
          }
        },
        // row method starts here
        getValueFromRow(row, name) {
          return get(row, name);
        },
        getCellSlotName(column) {
          if (has(column,"slot_name")) {
            return column.slot_name;
          }
          return column.name.replace(/\./g,'_');
        },
        // row method ends here
        resetSort() {
          this.query.sort = [];
          this.filter(!this.preservePageOnDataChange);
        },
        emitQueryParams(page = null) {
          let queryParams = cloneDeep(this.query);
          let sort = map(queryParams.sort, o => omit(o, 'vbt_col_id'));
          let filters = queryParams.filters
          let per_page = clone(this.per_page);

          if (page == null) {
            if (this.preservePageOnDataChange) {
              page = this.page;
            } else {
              this.page = 1;
              page = 1;
            }
          }

          let payload = {
            sort: sort,
            filters: filters,
            per_page: per_page,
            page: page
          }

          this.$emit('on-change-query', payload);
        },
        columnClasses(column) {
          let classes = "";

          let default_text_alignment = "text-left";

          //decide text alignment class - starts here
          let alignments = ["text-justify","text-right","text-left","text-center"];
          if (has(column, "column_text_alignment") && includes(alignments, column.column_text_alignment)) {
            classes = classes + " " + column.column_text_alignment;
          } else {
            classes = classes + " " + default_text_alignment;
          }
          //decide text alignment class - ends here

          // adding user defined classes to rows - starts here
          if (has(column, "column_classes")) {
            classes = classes + " " + column.column_classes;
          }
          // adding user defined classes to rows - ends here

          // adding classes for sortable column - starts here
          if (this.isSortableColumn(column)) {
            classes = classes + " vbt-sort-cursor";
          }
          // adding classes for sortable column - ends here

          return classes;
        },

        handleShiftKey() {
          ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
              document.onselectstart = function() {
                return !(e.key == "Shift" && e.shiftKey == true);
              }
            });
          });
        },
        emitActionEvent(action) {
          let payload = {
            event_payload : cloneDeep(action.event_payload)
          }

          if (this.isSelectable) {
            payload.selectedItems = cloneDeep(this.selected_items);
          }

          this.$emit(action.event_name,payload);
        },
    },
    computed: {
          rowCount() {
            return this.totalRows;
          },
          selectedItemsCount() {
            return this.selected_items.length;
          },
          filteredResultsCount() {
            return this.temp_filtered_results.length;
          },
          uniqueId() {
            let unique_id = "";

            if (!this.hasUniqueId) {
              unique_id = "vbt_id";
              return unique_id;
            }
            this.vbt_columns.some((column, key) => {
              if (has(column, 'uniqueId') && column.uniqueId === true) {
                unique_id = column.name;
                return true;
              }
            });

            return unique_id;
          },
          hasUniqueId() {
            let has_unique_id = false;

            this.vbt_columns.some((column, key) => {
              if (has(column, 'uniqueId') && column.uniqueId === true) {
                has_unique_id = true;
                return true;
              }
            });

            return has_unique_id;
          },

          // pagination info computed properties - start

          currentPageRowsLength() {
            return this.vbt_rows.length;
          },

          filteredRowsLength() {
            return this.rowCount;
          },

          originalRowsLength() {
            return this.rowCount;
          },

          // pagination info computed properties - end
          rowHighlightColor() {
            return (this.highlight_row_hover) ? this.highlight_row_hover_color : "";
          },

          headerColSpan() {
            let count = (this.checkbox_rows) ? 1 : 0;

            count += this.vbt_columns.length;

            return count;
          },

          showFilterRow() {
            return false;
          },

          showPaginationRow() {
            let show_pagination_row = false;

            if (this.card_mode == false && (this.pagination == true || this.pagination_info == true || this.selected_rows_info == true)) {
              show_pagination_row = true;
            }

            return show_pagination_row;
          },

          currentPageSelectionCount() {
            let result = [];
            if (!this.hasUniqueId) {
              result = intersectionWith(this.vbt_rows, this.selected_items, isEqual);
            } else {
              result = intersectionBy(this.vbt_rows, this.selected_items, this.uniqueId);
            }
            return result.length;
        },
        tableClasses() {
          let classes = "";
          if (typeof this.classes.table == "string") {
            return this.classes.table;
          } else if (typeof this.classes.table == "object") {
            Object.entries(this.classes.table).forEach(([key, value]) => {
              if (typeof value == "boolean" && value) {
                classes += key;
              } else if (typeof value == "function") {
                let truth = value(this.rows);
                if (typeof truth == "boolean" && truth) {
                  classes += " ";
                  classes += key;
                }
                    }
                });
            }
            return classes;
        },
        tableWrapperClasses() {
          let classes = "";
          let defaultClasses = "table-responsive";

          if (!this.classes.tableWrapper && this.classes.tableWrapper != "") {
            return defaultClasses;
          }

          return (typeof this.classes.tableWrapper == "string") ? this.classes.tableWrapper : defaultClasses;
        },

        isSelectable() {
          return (this.checkbox_rows || this.rows_selectable);
        },
    },
    watch: {
          "query": {
            handler: function (newVal, oldVal) {
              this.emitQueryParams();
            },
            deep: true
          },
          per_page: {
            handler: function (newVal, oldVal) {
              this.emitQueryParams();
            }
          },
          pagination: {
            handler: function (newVal, oldVal) {
              this.emitQueryParams();
            }
          },
          rows: {
            handler: function (newVal, oldVal) {
              this.vbt_rows = cloneDeep(this.rows);

              if (this.preservePageOnDataChange) {
                let predictedTotalPage = Math.ceil(this.rowCount / this.per_page);
                if (predictedTotalPage != 0) {
                  this.page = (this.page <= predictedTotalPage) ? this.page : predictedTotalPage;
                } else {
                  this.page = 1;
                }
              }

              this.isFirstTime = false;
            },
            deep: true
          },
          columns: {
            handler: function (newVal, oldVal) {

              this.vbt_columns = cloneDeep(this.columns);

              this.vbt_columns = map(this.vbt_columns, function (element, index) {
                let extra = {};
                extra.vbt_col_id = index + 1;
                return extend({}, element, extra);
              });

              this.initFilterQueries();
            },
            deep: true
          },
          vbt_rows: {
            handler: function (newVal, oldVal) {
              // resetting the shift mode
              this.lastSelectedItemIndex = null;

              if (this.selected_items.length == 0) {
                // EventBus.$emit('unselect-select-all-items-checkbox');
                this.allRowsSelected = false;
                return;
              }

              let difference = [];

              if (!this.hasUniqueId) {
                difference = differenceWith(newVal, this.selected_items, isEqual);
              } else {
                difference = differenceBy(newVal, this.selected_items, this.uniqueId);
              }

              if (difference.length == 0) {
                // EventBus.$emit('select-select-all-items-checkbox');
                this.allRowsSelected = true;
              } else {
                this.allRowsSelected = false;
                // EventBus.$emit('unselect-select-all-items-checkbox');
              }

            },
            deep: true
          },

          page(newVal, oldVal) {
            this.emitQueryParams(newVal);
          },
          'config.multi_column_sort': {
            handler : function(newVal,oldVal) {
              this.resetSort();
            }
          }
    }
};
</script>

<style scoped>
.vbt-table-wrapper {
  position: relative;
}

.vbt-table-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: absolute;
  opacity: 0.7;
  z-index: 7777;
}

.vbt-table-loader-wrapper {
  height: 100%;
  width: 100%;
  justify-content: center;
}

.vbt-table-loader-wrapper .progress{
  margin-left: 40%;
  margin-right: 40%;
}

.vbt-select-all-checkbox {
  margin-bottom: 20px;
}

.vbt-sort-cursor {
  color: #333;
  cursor: pointer;
}
.custom-control-label {
  vertical-align: top;
}
.vbt-column-header {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}
input[type="search"] {
  -webkit-appearance: searchfield;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

/* Bootstrap 4 text input with clear icon on the right */

.has-clear-right {
  position: relative;
}

.has-clear-right .form-control {
  padding-right: 2.375rem;
}

.has-clear-right .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  font-weight: normal;
}

.has-clear-right .form-control-feedback:hover {
  color: red;
}
</style>
