<template lang="pug">
  .card
    .card-body
      .form-row
        label.form-control-label.required
          template(v-if="searching_price")
            | {{ '1 x ' + $t(`components.price_information_step.${this.price_category}`) + ': ' }}
            spinner(:small="true", label="Spinning")
          template(v-else)
            | {{ '1 x ' + $t(`components.price_information_step.${this.price_category}`) + ': ' + this.price }}

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import axios from 'axios'
import { BSpinner } from 'bootstrap-vue'
import _ from "lodash";

export default {
  name: "PriceInformationStep",
  props: {
    stepper_data: {
      type: Object,
      required: true
    },
    extra_data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      price: "",
      price_found: false,
      searching_price: true,
      initialized: false,
      price_category: this.extra_data.price_category,
      price_url: this.extra_data.price_url
    }
  },
  components: {
    'validation-provider': ValidationProvider,
    'spinner': BSpinner
  },
  methods: {
    search_price: function (licenceCategoryId) {
      this.searching_price = true
      this.price_found = false
      let self = this
      axios.get(this.extra_data.price_url,
          {
            params: this.build_params()
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
        console.log(response)
        self.price = response.data.price

        self.price_found = !response.data.error;
      }).catch(function (error) {
        console.log(error)
        self.price_found = false
      }).finally(function () {
        self.searching_price = false
      });
    },
    build_params: function () {
      if (this.price_category === 'club_subscription') {
        let disciplines = _.clone(this.stepper_data.club_subscription_discipline_requests_attributes)

        _.remove(disciplines, function(discipline) {
          return !discipline.selected;
        });

        let discipline_ids = []
        function fetch_ids(value) {
          discipline_ids.push(value.discipline_id)
        }

        disciplines.forEach(fetch_ids)

        return {
          competition: this.stepper_data.competition,
          recreational: this.stepper_data.recreational,
          organizational: this.stepper_data.organizational,
          discipline_ids: discipline_ids,
          price_category: this.price_category,
          club_id: this.extra_data.club_id,
          valid_from: this.stepper_data.valid_from
        }
      }
      else if (this.price_category === 'licence') {
        return {
          price_category: this.price_category,
          licence_category_id: this.stepper_data.licence_category_id,
          individual: this.extra_data.individual_price_possible && this.stepper_data.individual_licence,
          period: this.stepper_data.period,
          licence_request_type: this.stepper_data.licence_request_type
        }
      }
      else if (this.price_category === 'race') {
        return {
          price_category: this.price_category,
          race_sub_category_id: this.stepper_data.race_sub_category_id,
          start_date: this.stepper_data.event_request_attributes.start_date,
        }
      }
      else if (this.price_category === 'tour') {
        return {
          price_category: this.price_category,
          tour_type: this.stepper_data.tour_type_id,
          start_date: this.stepper_data.event_request_attributes.start_date,
        }
      }
    }
  },
  watch: {
    'stepper_data.competition': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.recreational': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.organizational': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.club_subscription_discipline_requests_attributes': {
      immediate: true,
      deep: true,
      handler(newCategoryId, oldCategoryId) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.licence_category_id': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.individual_licence': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.period': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.race_sub_category_id': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
    'stepper_data.event_request_attributes.start_date': {
      immediate: true,
      handler(newPeriod, oldPeriod) {
        if (this.initialized)
        {
          this.search_price()
        }
      }
    },
  },
  mounted: function () {
    this.search_price();
    this.initialized = true
  }
}
</script>
