<template lang="pug">
  div.w-75
    template(v-if='searching_for_licence_categories')
      // Hidden input has been put here so that validations can never pass when loading licence_categories
      validation-provider.hidden(rules='required', v-slot='{ errors }')
        input#hidden_input(v-model="hidden_input", name="hidden_input", type="text")
      b-spinner.small(label="Spinning")
      | {{ $t('components.licence_category_selector.searching_for_licence_categories') }}
    template(v-else-if='licence_categories.length == 0')
      // Hidden input has been put here so that validations can never pass when loading licence_categories
      validation-provider.hidden(rules='required', v-slot='{ errors }')
        input#hidden_input2(v-model="hidden_input", name="hidden_input2", type="text")
      | {{ $t('components.licence_category_selector.no_licence_categories') }}
    template(v-else-if='licence_categories.length == 1')
      .row
        .col-md-6
          label.form-control-label.required {{$t('components.licence_category_selector.category_id')}}
        .col-md-6
          div {{ licence_categories[0].name }}
    template(v-else)
      validation-provider.fields.mt-2.string.required.licence_category_id(rules='required', v-slot='{ errors }')
        .float-input__container.active.clearfix
          label.form-control-label.select.required(for='licence_category_id') {{$t('components.licence_category_selector.category_id')}}
          div(:class="errors[0] ? 'select-dropdown is-invalid' : 'select-dropdown'")
            // Do NOT change to v-model, there is a bug on chrome ios and android browser for vue, those pickers don't fire change events
            select#licence_category_id.select.required(name='licence_category_id' :value='licence_category_id' @input='evt=>licence_category_id=evt.target.value')
              option(value='')
              template(v-for="category in licence_categories")
                option(:value='category.id', :selected='category.id === licence_category_id') {{category.name}}
          .invalid-feedback {{ errors[0] }}
</template>

<script>
  import {BSpinner} from 'bootstrap-vue'
  import {ValidationProvider} from 'vee-validate'
  import axios from "axios";
  import moment from "moment";

  export default {
    name: "LicenceCategorySelector",
    props: {
      birthday: {
        type: String,
        required: true
      },
      gender: {
        type: String,
        required: true
      },
      licence_category_search_path: {
        type: String,
        required: true
      },
      period: {
        type: String,
        required: true
      },
      club_id: {
        type: Number,
        required: false
      },
      initial_value: {
        type: Number,
        required: false
      }
    },
    data: function () {
      return {
        licence_category_id: null,
        licence_categories: [],
        searching_for_licence_categories: false,
        hidden_input: null,
        process_initial_value: true
      }
    },
    computed: {
    },
    components: {
      'validation-provider': ValidationProvider,
      BSpinner,
    },
    methods: {
      find_licence_category_name: function (licenceCategoryId) {
        let licence_category_name = null

        for (let i = 0; i < this.licence_categories.length; i++) {
          if (this.licence_categories[i].id === parseInt(licenceCategoryId)) {
            licence_category_name = this.licence_categories[i].name
            break
          }
        }

        return licence_category_name
      },
      search_licence_categories: function () {
        this.searching_for_licence_categories = true

        let birth_year = moment(this.birthday,"YYYY-MM-DD").year();
        let reference_year = moment().year();
        if(this.period == "next"){
          reference_year += 1
        }
        // The age that you are going to be in the year of the licence (not the age you are at the start)
        let age = reference_year - birth_year

        let params = {"age": age, "gender": this.gender, "club_id": this.club_id, "period": this.period}

        let self = this

        axios.get(this.licence_category_search_path, {params: params}).then(function (response) {
          self.licence_categories = response.data
          if(self.licence_categories.length == 0){
            self.licence_category_id = null
          }
          else if(self.licence_categories.length == 1){
            self.licence_category_id = self.licence_categories[0]["id"]
          }
          else if(self.process_initial_value && self.initial_value != null &&
                  self.licence_categories.some(licence_category => licence_category.id == self.initial_value )) {
            self.licence_category_id = self.initial_value
            self.process_initial_value = false
          }
          else if(!self.licence_categories.some(licence_category => licence_category.id == self.licence_category_id )) {
            self.licence_category_id = null
          }
        }).catch(function (error) {
          self.licence_categories = []
          self.licence_category_id = null
        }).finally(function () {
          self.searching_for_licence_categories = false
        });
      }
    },
    watch: {
      birthday: function (newVal, oldVal) {
        this.search_licence_categories();
      },
      gender: function (newVal, oldVal) {
        this.search_licence_categories();
      },
      period: function (newVal, oldVal) {
        this.search_licence_categories();
      },
      licence_category_id: function (newCategoryId, oldCategoryId) {
        let licence_category_name = this.find_licence_category_name(newCategoryId);

        this.$emit('change', [newCategoryId, licence_category_name])
      }
    },
    mounted() {
      this.search_licence_categories();
    }
  }
</script>
